import React from 'react';
import Banner from '../../assets/images/computing-server-banner.png';
import BannerWrap from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import 'swiper/css';
import 'swiper/css/navigation';
import * as styles from './index.module.less';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';

export default function ComputingServerPage() {
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        bannerStyle={{
          opacity: 1,
          // backgroundSize: 'auto 100%',
          // backgroundRepeat: 'no-repeat',
          // backgroundColor: '#113C81',
        }}
        title={<span style={{ color: '#fff' }}>土星云自然散热服务器</span>}
        desc={
          <>
            <div style={{ color: '#fff' }}>源于航天级自然散热专利技术</div>
            <div style={{ color: '#fff' }}>适合私有化部署的算力服务器</div>
          </>
        }
      />
      <SectionWrap>
        <div
          style={{
            display: 'flex',
            gap: '40px',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
            textAlign: 'center',
            fontSize: 18,
            fontWeight: 700,
          }}>
          <div>
            <img src="/images/computing-server-goods-1.png" alt="" style={{ maxWidth: 400 }} />
            <div>
              SR710A- <b>塔式</b>
            </div>
          </div>
          <div>
            <img src="/images/computing-server-goods-2.jpg" alt="" style={{ maxWidth: 400 }} />
            <div>
              SR710A- <b>机架式</b>
            </div>
          </div>
        </div>
        <div style={{ marginTop: 40 }}>
          SR710A 是基于NVIDIA GPU模组、AMD
          X86处理器打造的大算力设备，具有自然散热、大显存、高能效、低PUE等特点，可广泛应用于大模型的推理与训练，满足高校、科研单位、中小企业等用户的中小型算力中心建设需求。
        </div>
      </SectionWrap>

      <SectionWrap
        title="土星云算力服务器SR710A - 推训一体"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/icon/ziransanre.png" alt="" />
            </div>
            <div className={styles.niceTitle}>自然散热</div>
            <div className={styles.niceContent}>
              无需专业机房，可运行于办公室、厂房等环境，建设周期短，运营成本低
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/icon/daxiancun.png" alt="" />
            </div>
            <div className={styles.niceTitle}>大显存</div>
            <div className={styles.niceContent}>
              单台服务器可以完成GPT-175B等千亿参数大模型的推理和训练
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img width={55} height={55} src="/icon/gaoxiaoneng.png" alt="" />
            </div>
            <div className={styles.niceTitle}>高能效</div>
            <div className={styles.niceContent}>
              4000TOPS/2U高度超高算力体积密度、4000TOPS/1.36kW超高算力功率密度
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img width={55} height={55} src="/icon/pue.png" alt="" />
            </div>
            <div className={styles.niceTitle}>低PUE</div>
            <div className={styles.niceContent}>可助力建设绿色数据中心，实现PUE接近1.00</div>
          </div>
        </div>
      </SectionWrap>

      <SectionWrap title="科学AI大模型优选算力设备">
        <div className={styles.niceList}>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/icon/daxiancun.png" alt="" />
            </div>
            <div className={styles.niceTitle}>大显存</div>
            <div className={styles.niceContent}>单机最大支持1TB显存，可实现千亿参数大模型部署</div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/icon/siyubushu.png" alt="" />
            </div>
            <div className={styles.niceTitle}>私域化部署</div>
            <div className={styles.niceContent}>
              自然散热，噪音小，功耗低，可办公室部署，根据需求灵活配置使用，更好保障数据安全
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img width={55} height={55} src="/icon/tuixunyiti.png" alt="" />
            </div>
            <div className={styles.niceTitle}>推训一体</div>
            <div className={styles.niceContent}>
              多SOC芯片架构，CUDA core、Tensor Core、DLA算力融合，满足大规模推理与小规模训练需求
            </div>
          </div>
        </div>
      </SectionWrap>

      <SectionWrap
        title="中小企业大模型部署"
        background={'linear-gradient(180deg,#fdfeff,#ebf3ff)'}>
        <div className={styles.niceList}>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/icon/damoxing.png" alt="" />
            </div>
            <div className={styles.niceTitle}>大模型</div>
            <div className={styles.niceContent}>
              单台SR710A可实现千亿大模型的精准推理，以及私有数据小规模训练
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img src="/icon/gaoxiaoneng.png" alt="" />
            </div>
            <div className={styles.niceTitle}>高能效</div>
            <div className={styles.niceContent} style={{ textAlign: 'center' }}>
              单台1360W功耗可提供4000TOPS算力
            </div>
          </div>
          <div className={styles.niceItem}>
            <div className={styles.niceIcon}>
              <img width={55} height={55} src="/icon/yibushu.png" alt="" />
            </div>
            <div className={styles.niceTitle}>易部署</div>
            <div className={styles.niceContent}>
              轻松在非专业IDC中部署算力，便利性强，更高环境适应能力
            </div>
          </div>
        </div>
      </SectionWrap>

      <SectionWrap title="产品规格">
        <table className={styles.normsTable}>
          <thead>
            <tr>
              <th colSpan={2}></th>
              <th>SR710A-16C</th>
              <th>SR710A-08C</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan={4} style={{ width: '5em' }}>
                GPU 模组
              </td>
              <td style={{ width: '7em' }}>AI算力</td>
              <td>{'>4000TOPS@INT8'}</td>
              <td>{'>2000TOPS@INT8'}</td>
            </tr>
            <tr>
              <td>显存容量</td>
              <td>{'>1000GB'}</td>
              <td>{'>500GB'}</td>
            </tr>
            <tr>
              <td>解码器</td>
              <td>{'>320*1080p30(H.265)'}</td>
              <td>{'>160*1080p30(H.265)'}</td>
            </tr>
            <tr>
              <td>主CPU通信</td>
              <td>16*PCIE4.0 X4</td>
              <td>8*PCIE4.0 X8</td>
            </tr>

            <tr>
              <td rowSpan={6}>处理器</td>
              <td>主CPU</td>
              <td colSpan={2}>AMD X86 CPU</td>
            </tr>
            <tr>
              <td>核数</td>
              <td colSpan={2}>16</td>
            </tr>
            <tr>
              <td>线程数</td>
              <td colSpan={2}>32</td>
            </tr>
            <tr>
              <td>频率</td>
              <td colSpan={2}>3.0GHz</td>
            </tr>
            <tr>
              <td>睿频</td>
              <td colSpan={2}>3.7GHz</td>
            </tr>
            <tr>
              <td>三级缓存</td>
              <td colSpan={2}>128MB</td>
            </tr>
            <tr>
              <td>内存</td>
              <td>内存容量</td>
              <td>4*LRDIMM，速率最高支持3200MT/s，标配256GB，可选512GB</td>
              <td>4*LRDIMM，速率最高支持3200MT/s，标配128GB，可选256/512GB</td>
            </tr>
            <tr>
              <td>存储</td>
              <td>存储容量</td>
              <td colSpan={2}>2*SATA3.0 共16TB 可增PCIE4.0X2扩展硬盘，扩展容量为4TB</td>
            </tr>
            <tr>
              <td rowSpan={3}>I/O</td>
              <td>千兆以太网</td>
              <td colSpan={2}>8 Ports 1000Base-T(RJ45)（含1个管理网口）</td>
            </tr>
            <tr>
              <td>万兆以太网</td>
              <td colSpan={2}>标配2 Ports 25GbE(SFP28)，可选4 Ports 25GbE(SFP28)</td>
            </tr>
            <tr>
              <td>接口</td>
              <td colSpan={2}>2个(Type-A) USB3.2Gen1接口+1个串口+1个VGA接口</td>
            </tr>
            <tr>
              <td rowSpan={3}>支持软件</td>
              <td>操作系统</td>
              <td colSpan={2}>Ubuntu</td>
            </tr>
            <tr>
              <td>GPU工具</td>
              <td colSpan={2}>CUDA</td>
            </tr>
            <tr>
              <td>AI框架</td>
              <td colSpan={2}>Pytorch、Tensorflow、Mxnet</td>
            </tr>
            <tr>
              <td>系统管理</td>
              <td>iBMC</td>
              <td colSpan={2}>支持IPMI、SOL、KVMoverIP以及虚拟媒体，支持远程更新</td>
            </tr>
            <tr>
              <td rowSpan={2}>电源</td>
              <td>供电模式</td>
              <td>2个热插拔1600W交流电源模块，支持1+1冗余（热备）</td>
              <td>2个热插拔1200W交流电源模块，支持1+1冗余（热备）</td>
            </tr>
            <tr>
              <td>输入电压范围</td>
              <td colSpan={2}>100-240V@60/50Hz</td>
            </tr>
            <tr>
              <td rowSpan={2}>功耗</td>
              <td>功耗(最大)</td>
              <td>1360W</td>
              <td>880W</td>
            </tr>
            <tr>
              <td>功耗(待机)</td>
              <td>450W</td>
              <td>290W</td>
            </tr>
            <tr>
              <td rowSpan={4}>环境</td>
              <td rowSpan={3}>工作条件</td>
              <td colSpan={2}>
                工作温度：0℃~+40℃（满载、无风扇）@塔式 0℃~+55℃（满载、低噪风扇）@塔式
              </td>
            </tr>
            <tr>
              <td colSpan={2}>0℃~+40℃（满载、低噪风扇）@机架式 0℃~+55℃（满载、标准风扇）@机架式</td>
            </tr>
            <tr>
              <td colSpan={2}>工作湿度：40℃@95%相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>储存条件</td>
              <td colSpan={2}>储存温度：-40℃~+85℃ ，储存湿度：60℃@95%相对湿度，无冷凝</td>
            </tr>
            <tr>
              <td>机械</td>
              <td>安装方式/尺寸</td>
              <td colSpan={2}>
                塔式：包络 709长×302宽×603高（单位：mm）机架式：2U 430宽×88高×700深（单位：mm）
              </td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </Layout>
  );
}

export function Head() {
  return <SEO title="公有云" />;
}
