// extracted by mini-css-extract-plugin
export var moreText = "index-module--moreText--Mxblw";
export var navBtn = "index-module--navBtn--WsN5v";
export var niceContent = "index-module--niceContent--kDE8k";
export var niceDesc = "index-module--niceDesc--iHvq6";
export var niceIcon = "index-module--niceIcon--Y6Qq8";
export var niceItem = "index-module--niceItem--90Y1f";
export var niceList = "index-module--niceList--ffNBT";
export var niceTitle = "index-module--niceTitle--4JB98";
export var normsTable = "index-module--normsTable---Wcg7";
export var plan = "index-module--plan--0iUOm";
export var planItem = "index-module--planItem--m6Atc";
export var planItemContent = "index-module--planItemContent--MK41g";
export var planItemTitle = "index-module--planItemTitle--VvpCs";
export var planItemWrap = "index-module--planItemWrap--hFBAo";
export var priceContainer = "index-module--priceContainer--d0UUZ";
export var priceCover = "index-module--priceCover--4piVP";
export var priceItem = "index-module--priceItem--V2l+X";
export var priceItemText = "index-module--priceItemText--kw4rC";
export var priceItemTitle = "index-module--priceItemTitle--mzSnC";
export var priceItemUl = "index-module--priceItemUl--OIdHw";
export var priceItemUnit = "index-module--priceItemUnit--BnfiH";
export var tabContent = "index-module--tabContent--ntVvh";
export var tabContentItem = "index-module--tabContentItem--XP0BD";
export var tabContentItemChild = "index-module--tabContentItemChild--zVY3B";
export var tabContentTitle = "index-module--tabContentTitle--z6Ajb";
export var tabIcon = "index-module--tabIcon--wfeuJ";
export var tabItem = "index-module--tabItem--n-776";
export var tabItemActive = "index-module--tabItemActive--rZCgT";
export var tabList = "index-module--tabList--jvJL9";
export var tdBase = "index-module--tdBase--EnEq9";